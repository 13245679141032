import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { getUserData } from "@/auth/utils";

export default function useProductList() {
  // Use toast
  const toast = useToast();

  const refProductListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'PRODUCT ID', sortable: true },
    { key: "index", label: "ลำดับ" },
    { key: "productCode", label: "รหัสสินค้า (SKU)" },
    { key: "productName", label: "ชื่อสินค้า" },
    // { key: "productColor", label: "โค้ดสี", },
    { key: "productColor", label: "โค้ดสี" },
    {
      key: "sumStockMaster",
      label: "คลังสินค้าทั้งหมด",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    },
    {
      key: "sumStockPartner",
      label: "คลังสินค้าตัวแทน",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    },
    {
      key: "balance",
      label: "คลังสินค้าคงเหลือ",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    },
    {
      key: "realBalance",
      label: "คลังสินค้าคงเหลือจริง",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    },
    // { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalProducts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const productNameFilter = ref(null);
  const productCategoryFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProductListTable.value
      ? refProductListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    };
  });

  const refetchData = () => {
    refProductListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      productNameFilter,
      productCategoryFilter,
      statusFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchProducts = (ctx, callback) => {
    const userData = getUserData();

    store
      .dispatch("warehouses/fetchSubinventorys", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        productName: productNameFilter.value,
        productCategory: productCategoryFilter.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const { data, total } = response;
        console.log("data", data);
        callback(data);
        totalProducts.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching products list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = (status) => {
    if (status === "Popular Products") return "primary";
    if (status === "Best Seller" || status === "Active") return "success";
    if (status === "Recommended Products") return "info";
    if (status === "Inactive") return "secondary";
    return "primary";
  };

  return {
    fetchProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductListTable,

    resolveStatusVariant,
    refetchData,

    // Extra Filters
    productNameFilter,
    productCategoryFilter,
    statusFilter,
  };
}
